import React from "react"
import * as styles from './expose.module.scss';
import * as shortStyles from './expose-short.module.scss';

import RcImmoLogo from "../../images/rc_immo.png"
import RcLogo from "../../images/logo.png"
import PlaceSVG from "../../icons/envato/place.svg"
import BlueprintSVG from "../../icons/envato/blueprint.svg"
import HouseSVG from "../../icons/envato/house.svg"
import RcImmoPrice, { getImmoPrice } from "../../helper-components/immo/rcImmoPrice"
import RoomSVG from "../../icons/envato/room.svg"
import AnnualSVG from "../../icons/envato/immoConstructionYear.svg"

const ExposeShort = ( { immoContact, immo, styleProperty, titleBild } ) => {

  if (!immo) {
    return <div>keine Immobilie übermittelt</div>
  }

  let flaeche = immo.flaechen.gesamtflaeche;

  if (!flaeche) {
    flaeche = immo.flaechen.grundstuecksflaeche;
  }

  if (!flaeche) {
    flaeche = immo.flaechen.nutzflaeche;
  }

  if (!flaeche) {
    flaeche = immo.flaechen.wohnflaeche;
  }

  return <div className={styles.expose}>

    <div className={"footer " + shortStyles.footer}>
      <div className="d-flex justify-content-between align-items-center h-100">

        <div>
          <p>RC Immobilien</p>
          <p>A-4600 Wels, Durisolstraße 7</p>
        </div>

        <div>
          <p>office@reischauer.at</p>
          <p>www.reischauer.at</p>
        </div>
      </div>

    </div>

    <div className={styles.page}>

      <div className={styles.rcHeader + " mb-3"}>
        <div className="d-flex align-items-center justify-content-between">

          <img src={RcImmoLogo} />
          <img src={RcLogo} className={styles.rcLogo}/>

        </div>
      </div>

      <div className={"my-2 text-center"}>
        <h4 className={styles.exposeTitle}>Exposé</h4>
      </div>

      <img className={styles.headerImage + " img-fluid mx-auto"} src={ titleBild?.daten?.url}/>

      <div className="d-flex w-100 justify-content-between my-3">
        <p className="opacity-75 text-left">Objekt Nr. {immo.objektnr_extern}</p>

        <p className={styles.geo + " text-right opacity-75 d-flex align-items-center"}>
          <PlaceSVG className={"mr-2"} />
          {immo.geo.ort}
        </p>
      </div>

      <h1 className={"mt-1 mb-0 " + styles.caption}>{immo.title}</h1>

      {styleProperty === 'normal' &&
      <table className={"table " + styles.propertyTable}>
        <tbody>
        <tr className="pt-3">

          <td>

            <div className={styles.immoProperty}>
              <div className={styles.immoPropertyLabel + " d-flex flex-row align-items-center"}>
                <BlueprintSVG className={"mr-2"}/>
                Fläche
              </div>

              <div className={styles.immoPropertyValue}>
                {(flaeche + "").replace('.', ',')} m²
              </div>
            </div>

          </td>

          {getImmoPrice(immo).price &&
          <td>
            <div className="immoProperty">

              <div className={styles.immoPropertyLabel + " d-flex flex-row align-items-center"}>
                <HouseSVG className={"mr-2 "}/>
                Preis
              </div>

              <div className={styles.immoPropertyValue}>
                <RcImmoPrice subInfoPlacement={"none"} immo={immo}/>
              </div>
            </div>
          </td>
          }

          <td>
            <div className="immoProperty">

              <div className={styles.immoPropertyLabel + " d-flex flex-row align-items-center"}>
                <RoomSVG className={"mr-2"}/>
                Zimmer
              </div>

              <div className={styles.immoPropertyValue}>
                {immo.flaechen.anzahl_zimmer ? Number.parseInt(immo.flaechen.anzahl_zimmer) : 1}
                <span className="sub d-inline-block">&nbsp;Zimmer</span>
              </div>
            </div>
          </td>

          {immo.zustand_angaben.baujahr &&
          <td>
            <div className="immoProperty">

              <div className={styles.immoPropertyLabel + " d-flex flex-row align-items-center"}>
                <AnnualSVG className={"mr-2"}/>
                Baujahr
              </div>

              <div className={styles.immoPropertyValue}>
                {immo.zustand_angaben.baujahr}
              </div>

            </div>
          </td>
          }

        </tr>
        </tbody>
      </table>
      }

      {styleProperty === 'alternative' &&
      <h4 className="mt-3 mb-0">
        {(immo.flaechen.gesamtflaeche + "").replace('.', ',')} m²,
        &nbsp; <RcImmoPrice immo={immo} showFull={true} subInfoPlacement={'none'} />
      </h4>}


      <div className={shortStyles.contact + " mt-5 pt-2 pb-0 px-2"}>
        <div className="d-flex justify-content-between">
            <img className={shortStyles.qr} src={immo.qrCodeDataUrl} />

            <div className={"d-flex align-items-center"}>

              <div className={"mr-3"}>
                <p>{immoContact?.fullname}</p>
                <p>{immoContact?.phone}</p>
                <p>{immoContact?.mail?.toLowerCase()}</p>

                <p className="mt-3">
                  https://www.reischauer.at
                </p>

              </div>

              {immoContact?.avatar?.url &&
              <img className={shortStyles.contactImage} src={immoContact?.avatar?.url}/>
              }

            </div>
        </div>
      </div>

    </div>


  </div>

};

export default ExposeShort;
